import React from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { useSelector } from "react-redux"
import { canAccessSizing } from "../utils/checkRoles"
import { cedarGreen } from "../components/layout"
import CircularProgress from "@material-ui/core/CircularProgress"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  usePDF,
  PDFViewer,
  Font
} from "@react-pdf/renderer"

import { createTableHeader, createTableRow, tableStyle, createTableRowWithColor } from '../utils/pdfStylesVeichi'
import { getMonthFromNumber, getMonthSmallFromNumber, roundCashFormat } from "../utils/common"

// seperate pdf page quite heavy so code split should help?

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
    fontFamily: `Source Sans Pro`
  },
  section: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  line: {
    padding: 10,
  },
  boxClear: {
    width: "48vw",
    overflow: "hidden",
    padding: 5,
    marginTop: 5
  },

  box: {
    width: "48vw",
    overflow: "hidden",
    border: "1 solid black",
    padding: 5,
    marginTop: 5
  },

  boxWide: {
    width: "98vw",
    overflow: "hidden",
    border: "1 solid black",
    padding: 5,
    marginTop: 5,
  }
})

const PdfDoc = ({
  driveLogo,
  imageSrc, // the w/h diagram
  user,
  result,
  averageDailyYieldChart,
  averageDailyYieldMonthChart,
  irrigationResult
}) => {
  Font.register({
    family: 'Source Sans Pro', fonts: [
      { src: `/Source-Sans-Pro2.ttf` },
      { fontWeight: 'bold', src: `/Source-Sans-Pro-Bold2.ttf` }
    ]
  });

  Font.registerHyphenationCallback(word => [word]);

  console.log({...result.pumpResult})

  return (
    <Document title="Veichi Sizing PDF" creator="Cedar Solar">
      <Page size="A4" style={styles.body}>
        <Image src={driveLogo} style={{ width: 50, height: 50, position: 'absolute', left: 20, top: 20 }} />
        <View style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', width: '100%' }}>
          <Text style={{ marginTop: 8, fontSize: 25, fontWeight: 'bold', textDecoration: 'underline' }}>Veichi Drive Sizing Report</Text>
          <View />
        </View>

        {/* <View style={{ display: 'flex', flexDirection: "row" }}>
          <Image src={driveLogo} style={{ width: 50, height: 50 }} />
          <Text style={{ marginLeft: 20, marginTop: 10, fontSize: 25, fontWeight: 'bold' }}>Veichi Drive Sizing Report</Text>
        </View> */}
        <View style={{ marginTop: 20 }}></View>
        <View style={{ display: 'flex', flexDirection: "row" }}>
          <View style={{ width: "50%" }}>
            <View style={{ marginBottom: 10 }}>
              <Text style={{ marginBottom: 6, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Client Information
              </Text>
              <Text style={{ fontSize: 12, textOverflow: "ellipsis" }}>
                Customer: {result.customer}
              </Text>
              <Text
                style={{ marginTop: 2, fontSize: 12, textOverflow: "ellipsis" }}
              >
                Cell Nr: {result.phone}
              </Text>
              <Text
                style={{ marginTop: 2, fontSize: 12, textOverflow: "ellipsis" }}
              >
                Email: {result.email}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                Closest Town: {result.pumpResult.town.name}
              </Text>

            </View>

            <View style={{ marginBottom: 10 }}>
              <Text style={{ marginBottom: 6, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Component Information
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                Desired Flow at TDH (L/h): {result.pumpResult.thd}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                Pump Power rating (kW): {result.pumpResult.powerRating}
              </Text>

              <Text style={{ marginTop: 4, fontSize: 10, fontWeight: 'bold' }}>
                Panels:
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                 Watt: {result.pumpResult.panels.watt}  VMP: {result.pumpResult.panels.vmp}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                VOC: {result.pumpResult.panels.voc}
              </Text>
            </View>

            <View style={{ marginBottom: 10 }}>
              <Text style={{ marginBottom: 6, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Sizing Results
              </Text>
              {!result.pumpResult.manual && <>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Recommended Drive: {result.pumpResult.drive.name}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Average Daily Yield (m<sup>3</sup>): {result.pumpResult.flows.averageDriveFlowPerDayAtTownCubicLiters}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Number of Strings: {result.pumpResult.panels.stringNeededRounded}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Total Number of Panels: {result.pumpResult.panels.totalPanels}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Panels per String: {result.pumpResult.panels.panelsPerString}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Total PV Watt: {result.pumpResult.panels.totalWatt}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Sizing Factor: {result.pumpResult.sizingFactor}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  System Voc: {result.pumpResult.systemVoc}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  System VMP: {result.pumpResult.systemVmp}
                </Text>
              </>}
              {result.pumpResult.manual &&
                <>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Manual Sizing
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Drive: {result.pumpResult.drive.name}
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Average Daily Yield (m<sup>3</sup>): {result.pumpResult.flows.averageDriveFlowPerDayAtTownCubicLiters}
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Number of Strings: {result.pumpResult.panels.stringNeededRounded}
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Total Number of Panels: {result.pumpResult.panels.totalPanels}
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Panels per String: {result.pumpResult.panels.panelsPerString}
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Total PV Watt: {result.pumpResult.panels.totalWatt}
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    Sizing Factor: {result.pumpResult.sizingFactor}
                  </Text>
                  <Text style={{ marginTop: 2, fontSize: 12 }}>
                    System Voc: {result.pumpResult.systemVoc}
                  </Text>
                </>}
              {result.pumpResult.warnings && result.pumpResult.warnings.length > 0 &&
                <Text style={{ marginTop: 2, fontSize: 12, color: 'red' }}>
                  Warnings:
                </Text>}
              {result.pumpResult.warnings && result.pumpResult.warnings.map(a => <Text key={a} style={{ marginTop: 2, marginBottom: 2, fontSize: 12, color: 'red' }}>
                {a}
              </Text>)}

            </View>

            <View style={{ marginBottom: 10 }}>
              <Text style={{ marginBottom: 6, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Technical Specifications
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                Drive model: {result.pumpResult.drive.name}
              </Text>


              <Text style={{ marginTop: 2, fontSize: 12 }}>
                Drive Rated Output (A): {result.pumpResult.drive.ratedOutputApp}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                IP Rating: {result.pumpResult.drive.ipRating}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                Max DC Voltage Input: {result.pumpResult.drive.maxVOC}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                Drive AC Voltage Output: {result.pumpResult.drive.acVoltage}
              </Text>


            </View>




          </View>
          <View style={{ width: "50%" }}>

            <View>
              <Text style={{ marginTop: 2, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Average Daily Yield
              </Text>
              <Image
                style={{ marginBottom: 10, width: 280 }}
                src={averageDailyYieldChart}
              />
            </View>
            <View>
              <Text style={{ marginTop: 2, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Average Daily Yield per Month
              </Text>
              <Image
                style={{ marginBottom: 10, width: 240 }}
                src={averageDailyYieldMonthChart}
              />
            </View>
            <View style={{ marginLeft: 10, marginTop: 10 }}>
              <Image src={imageSrc} style={{ width: 200 }} />
            </View>
            {/* <View style={{ marginLeft: 10, marginTop: 10 }}>
              <Image src={imageSrc} style={{ width: 100 }} />

              <Text style={{ marginTop: 2, fontSize: 13 }}>
                Size (mm)
              </Text>
              <Text style={{ marginTop: 2, fontSize: 12 }}>
                W: {result.pumpResult.drive.w} H: {result.pumpResult.drive.h} D: {result.pumpResult.drive.d}
              </Text>

            </View> */}

          </View>




        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={{ fontSize: 12 }}>
            I acknowledge that the information provided is correct. Inaccurate
            info will affect the water pump system's performance.
          </Text>
          <Text style={{ paddingTop: 30, fontSize: 12 }}>
            Name: ..........................................................{" "}
            Date: ..........................................................{" "}
          </Text>
          <Text style={{ paddingTop: 30, fontSize: 12 }}>
            Signature:
            ..........................................................{" "}
          </Text>

          <Text
            style={{ marginTop: 15, fontSize: 8 }}
          >{`Generated by: ${user.companyName ? user.companyName : ""} (${user.firstName ? user.firstName : ""
            } ${user.email})`} on {new Date().toDateString()}</Text>
        </View>

        {irrigationResult && irrigationResult.irrigationInput &&
          <><View style={{ marginTop: 20 }} break></View><View style={{ display: 'flex', flexDirection: "row" }}>
            <View style={{ width: "50%" }}>
              <View style={{ marginBottom: 10 }}>
                <Text style={{ marginBottom: 6, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                  Cashflow Inputs
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  System Cost (excl. VAT): {irrigationResult.irrigationInput.systemCost}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Deposit: {irrigationResult.irrigationInput.deposit}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Loan Period: {irrigationResult.irrigationInput.loanPeriod}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Interest Rate (p.a.): {irrigationResult.irrigationInput.interestRate}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Electricity Increase 5 Yr: {irrigationResult.irrigationInput.electricityIncrease5Yr}
                </Text>
                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Electricity Increase 6 Yr: {irrigationResult.irrigationInput.electricityIncrease6Yr}
                </Text>
                <View style={{ marginTop: 20 }}></View>
                <Text style={{ marginBottom: 6, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                  Consumption
                </Text>

                <Text style={{ marginTop: 2, fontSize: 12 }}>
                  Energy Cost (kWh): {irrigationResult.irrigationInput.averageEnergyCost}
                </Text>

                <View style={{ marginBottom: 10 }} />
                {createTableHeader(['Month', 'kWh'])}
                {irrigationResult.monthsKwh && irrigationResult.monthsKwh.map((a, i) => createTableRow([getMonthFromNumber(i), Math.round(a)]))}





                {/* <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Loan Repayment</TableCell>
            <TableCell>Electricity Savings</TableCell>
            <TableCell>Tax Savings</TableCell>
            <TableCell>Annual Cashflow</TableCell>
            <TableCell>Cumulative Cashflow</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {irrigationResult.data.loanYears.map(a=><TableRow>
            <TableCell>{a.year}</TableCell>
            <TableCell style={{background: a.loanRepayment<0?'red':'green'}}>{roundCashFormat(a.loanRepayment)}</TableCell>
            <TableCell style={{background: a.electricitySavings<0?'red':'green'}}>{roundCashFormat(a.electricitySavings)}</TableCell>
            <TableCell style={{background: a.taxSavings<0?'red':'green'}}>{roundCashFormat(a.taxSavings)}</TableCell>
            <TableCell style={{background: a.annualCashflow<0?'red':'green'}}>{roundCashFormat(a.annualCashflow)}</TableCell>
            <TableCell style={{background: a.cumulativeCashFlow<0?'red':'green'}}>{roundCashFormat(a.cumulativeCashFlow)}</TableCell>

            </TableRow>) */}


              </View>
            </View>


            {/* taxRate: result.irrigationInputs.taxRate,
        systemCost: result.irrigationInputs.systemCost,
        deposit: result.irrigationInputs.deposit,
        loanPeriod: result.irrigationInputs.loanPeriod,
        interestRate: result.irrigationInputs.interestRate,
        electricityIncrease5Yr: result.irrigationInputs.electricityIncrease5Yr,
        electricityIncrease6Yr: result.irrigationInputs.electricityIncrease6Yr,
        averageEnergyCost: result.irrigationInputs.averageEnergyCost,
        monthlyConsumptionCost: result.irrigationInputs.monthlyConsumptionCost */}

            <View style={{ width: "50%" }}>
              <View style={{ marginBottom: 10 }}>

                <View>
                  <Text style={{ marginTop: 2, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                    Monthly Electricity Usage
                  </Text>
                  <Image
                    style={{ marginBottom: 10, width: 240 }}
                    src={irrigationResult.monthlyElectricityUsageGraph} />
                </View>

                <View>
                  <Text style={{ marginTop: 2, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                    Daily System Performance
                  </Text>
                  <Image
                    style={{ marginBottom: 10, width: 240 }}
                    src={irrigationResult.dailySystemPerformanceGraph} />
                </View>

                <View>
                  <Text style={{ marginTop: 2, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                    Savings Potential
                  </Text>
                  <Image
                    style={{ marginBottom: 10, width: 240 }}
                    src={irrigationResult.savingsPotentialGraph} />
                </View>

                <View>
                  <Text style={{ marginTop: 2, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                    Average Daylight Pumping Hours
                  </Text>
                  <Image
                    style={{ marginBottom: 10, width: 240 }}
                    src={irrigationResult.daylightPumpingHoursGraph} />
                </View>


              </View>
            </View>
          </View>

            <View style={{ marginTop: 20 }} break>
              <Text style={{ marginBottom: 10, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Loan Based Cashflow Analysis
              </Text>
              {createTableHeader(['Year', 'Loan Repayment', 'Electricity Savings', 'Tax Savings', 'Annual Cashflow', 'Cumulative Cashflow'])}
              {irrigationResult.loanYears && irrigationResult.loanYears.map((a, i) => createTableRowWithColor([
                { value: a.year, color: 'white' },
                { value: roundCashFormat(a.loanRepayment), color: a.loanRepayment >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.electricitySavings), color: a.electricitySavings >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.taxSavings), color: a.taxSavings >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.annualCashflow), color: a.annualCashflow >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.cumulativeCashFlow), color: a.cumulativeCashFlow >= 0 ? '#43eb34' : '#eb3d34' }
              ]))}
              <View style={{ marginBottom: 4 }}></View>

              <Text style={{ marginTop: 2, fontSize: 14 }}>
              Solar kWh Generation Cost: {roundCashFormat(irrigationResult.solarKwhGenerationCostLoan)}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 14 }}>
              Break Even: {irrigationResult.breakEvenYearLoan} years and {irrigationResult.breakEvenMonthsLoan} months
              </Text>
              <Text style={{ marginTop: 2, fontSize: 14 }}>
                Net Present Value: {roundCashFormat(irrigationResult.netPresentValLoan)}
              </Text>
              </View>
              <View style={{ marginTop: 20 }} break>
              <Text style={{ marginBottom: 10, fontSize: 16, textDecoration: 'underline', fontWeight: 'bold' }}>
                Cash Based Cashflow Analysis
              </Text>
              {createTableHeader(['Year', 'Money Outlay', 'Electricity Savings', 'Tax Savings', 'Annual Cashflow', 'Cumulative Cashflow'])}
              {irrigationResult.cashYears && irrigationResult.cashYears.map((a, i) => createTableRowWithColor([
                { value: a.year, color: 'white' },
                { value: roundCashFormat(a.monthlyOutlay), color: a.monthlyOutlay >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.electricitySavings), color: a.electricitySavings >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.taxSavings), color: a.taxSavings >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.annualCashflow), color: a.annualCashflow >= 0 ? '#43eb34' : '#eb3d34' },
                { value: roundCashFormat(a.cumulativeCashFlow), color: a.cumulativeCashFlow >= 0 ? '#43eb34' : '#eb3d34' }
              ]))}

              <View style={{ marginBottom: 4 }}></View>
              <Text style={{ marginTop: 2, fontSize: 14 }}>
              Solar kWh Generation Cost: {roundCashFormat(irrigationResult.solarKwhGenerationCostCash)}
              </Text>
              <Text style={{ marginTop: 2, fontSize: 14 }}>
              Break Even: {irrigationResult.breakEvenYearCash} years and {irrigationResult.breakEvenMonthsCash} months
              </Text>
              <Text style={{ marginTop: 2, fontSize: 14 }}>
                Net Present Value: {roundCashFormat(irrigationResult.netPresentValCash)}
              </Text>

            </View>

          </>}





      </Page>
    </Document>
  )
}


const PDFButton = ({ location, userProfile, data }) => {

  const [instance, updateInstance] = usePDF({
    document: <PdfDoc
      driveLogo={data.driveLogo.childImageSharp.fluid.src}
      imageSrc={data.fileName.childImageSharp.fluid.src}
      user={userProfile}
      result={location.state.result}
      averageDailyYieldChart={location.state.dailyYieldChart}
      averageDailyYieldMonthChart={
        location.state.monthlyYieldChart
      }
      irrigationResult={{ ...location.state.irrigationResult, irrigationInput: location.state.irrigationInput, ...location.state.irrigationImages }}
    // calculatorInputs={location.state.inputs}
    // chartImage={location.state.chartImage}
    />
  });

  if (instance.loading) return <CircularProgress />;

  if (instance.error) return <Typography>Something went wrong generating the PDF, please contact us.</Typography>;

  return (<>
    <a href={instance.url}
      style={{ backgroundColor: cedarGreen }}
      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
      download={`${location.state.result.customer
        ? location.state.result.customer
        : "cedar_pumps"
        }_veichi.pdf`}>
      Download Now!
    </a>
  </>
  );

}

const AppVeichiPdf = ({ location }) => {
  const { loggedIn, userProfile, ready } = useSelector(state => state.db)
  const data = useStaticQuery(graphql`
    query PdfVeichiDiagramQuery {
      fileName: file(relativePath: { eq: "si23-1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      driveLogo: file(relativePath: { eq: "veichi-logo.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (loggedIn && canAccessSizing(userProfile.roles) && ready) {
    // console.log({...location.state})
    return (
      <Layout>
        <SEO title="Veichi Sizing PDF" />
        <Container component="main" maxWidth="md">
          <Typography component="h1" variant="h4">
            Sizing PDF Result
          </Typography>
          {!ready && <CircularProgress />}
          <Typography component="p" variant="body1">
            Detailed sizing PDF. Click the button for the PDF (may take a few
            seconds to generate)
          </Typography>
          <br />
          {location.state && location.state.result && userProfile && (
            <>
              <PDFButton location={location} userProfile={userProfile} data={data} />

              <br />
              <br />
              <br />

              <Button
                color="primary"
                variant="contained"
                onClick={() => window.history.back()}
              >
                Back to your Sizing
              </Button>

              <br />
              <br />

              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("/app/sizing/", { replace: true })}
              >
                New Sizing
              </Button>
            </>
          )}
        </Container>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <SEO title="App PDF" />
        <Container component="main" maxWidth="md">
          <Typography component="h2" variant="h6">
            Sizing PDF Result
          </Typography>
          <CircularProgress />
        </Container>
      </Layout>
    )
  }
}

export default AppVeichiPdf
